<template>
  <v-card>
    <v-card-title
      class="pb-1"
    >
      <v-avatar
        :color="iconColor"
        class="elevation-5"
      >
        <v-icon
          dark
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <v-spacer></v-spacer>
      <span
        class="display-2 font-weight-black"
      >
        {{ statistic }}
      </span>
    </v-card-title>
    <v-card-title
      class="pt-1"
    >
      <v-spacer></v-spacer>
      <span
        class="body-1 font-weight-black"
      >
        {{ statisticTitle }}
      </span>
    </v-card-title>

    <vue-apex-charts
      :options="areaChartOptions"
      :series="seriesData"
      type="area"
      height="100"
    />
  </v-card>
</template>


<script>
  import 'echarts/lib/chart/line'

  export default{
    name: 'StatisticsCardLine',

    components: {
    },

    props: {
      icon: {
        type: String,
        required: true
      },
      iconColor: {
        type: String,
        required: true,
        default: () => 'primary'
      },
      statistic: {
        type: Number,
        default: () => 0
      },
      statisticTitle: {
        type: String,
        default: () => ''
      },
      chartData: {
        type: Array,
        required: true,
        default: () => []
      },
      chartDays: {
        type: Array,
        required: true,
        default: () => []
      },
      color: {
        type: String,
        default: '#A9A2F6',
      },
      colorTo: {
        type: String,
        default: '#fff'
      },
      type: {
        type: String,
        default: 'line'
      }
    },

    data() {
      return {
        areaChartOptions: {
          grid: {
            show: false,
            padding: {
              top: 5,
              right: 0,
              bottom: 0,
              left: 0
            }
          },
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 2.5
          },
          colors: [this.color],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.7,
              opacityTo: 0.2,
              stops: [0, 80, 100]
            }
          },
          xaxis: {
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            categories: this.chartDays,
            labels: { show: false }
          },
          yaxis: [{
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: { left: 0, right: 0 },
          }],
          tooltip: {
            x: { show: true }
          },
        }
      }
    },

    computed: {
      seriesData() {
        return [{
          name: this.statisticTitle,
          data: this.chartData
        }]
      }
    },

    methods: {

    },

    created() {
    }
  }
</script>
